.RadioGroupBoxed_RadioGroupBoxedRow_RadioButton {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--label-quaternary);
  appearance: none;
  background-color: transparent;
  position: relative;
  transition: border-color 0.075s ease;
}

.RadioGroupBoxed_RadioGroupBoxedRow_RadioButton:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: background-color 0.075s ease, border-color 0.075s ease;
}

.RadioGroupBoxed_RadioGroupBoxedRow_RadioButton:checked {
  border-color: var(--accent-primary);
}

.RadioGroupBoxed_RadioGroupBoxedRow_RadioButton:checked:after {
  background-color: var(--accent-primary);
  border-color: var(--accent-primary);
}

.RadioGroupBoxed_RadioGroupBoxedRow_RadioButton:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--accent-primary);
}
