.VerificationCode_Character_Container {
  position: relative;
  height: 4rem;
}

.VerificationCode_Character_HiddenInput {
  outline: none;
  text-align: center;

  height: 100%;
  width: 100%;

  caret-color: transparent;
  color: transparent;
  background: transparent;
}

/* Chrome, Safari, Edge, Opera */
.VerificationCode_Character_HiddenInput::-webkit-outer-spin-button,
.VerificationCode_Character_HiddenInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
.VerificationCode_Character_HiddenInput[type='number'] {
  -moz-appearance: textfield;
}

.VerificationCode_Character_CharacterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  pointer-events: none;
  cursor: pointer;

  transition: border-color 0.15s;
}
