.Radio_Radio {
  background: transparent;
}

.Radio_Radio.Radio_Radio--disabled {
  background: var(--fill-secondary);
}

.Radio_Radio.Radio_Radio--checked {
  background: var(--accent-fill-primary);
}

.Radio_Radio:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: 0.3s;
  background-color: transparent;
  width: 0;
  height: 0;
}

.Radio_Radio.Radio_Radio--checked:after {
  background-color: var(--accent-primary);
  width: 0.375rem;
  height: 0.375rem;
}

.Radio_Radio--disabled.Radio_Radio--checked:after {
  background-color: var(--fill-secondary);
}
