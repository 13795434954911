.SlideInModal_Wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 300ms ease-in-out;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
  /* ${({ state }) =>
      state === 'entered' && 'background-color: rgba(0, 0, 0, 0.5)'};
    ${({ state }) => state === 'exited' && 'background-color: rgba(0, 0, 0, 0)'}; */
}

.SlideInModal_Wrapper.SlideInModal_Wrapper--entered {
  background-color: rgba(0, 0, 0, 0.5);
}

.SlideInModal_Wrapper.SlideInModal_Wrapper--exited {
  background-color: rgba(0, 0, 0, 0);
}

/**
  * This is a fix for mobile devices to avoid the modal to be scrollable
 */
@media screen and (max-width: 575px) {
  .SlideInModal_Wrapper {
    position: fixed;
  }
}
