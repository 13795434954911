.VerificationCode_SafariIOSVerificationCode_Container {
  height: 4rem;
  display: grid;
  grid-template-rows: 4rem;
  gap: 0.5rem;
  justify-content: center;
}

.VerificationCode_SafariIOSVerificationCode_SafariCharacterInput {
  outline: none;
  text-align: center;
  height: 100%;
  width: 100%;
  transition: border-color 0.2s;
}

/* Chrome, Safari, Edge, Opera */
.VerificationCode_SafariIOSVerificationCode_SafariCharacterInput::-webkit-outer-spin-button,
.VerificationCode_SafariIOSVerificationCode_SafariCharacterInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
.VerificationCode_SafariIOSVerificationCode_SafariCharacterInput[type='number'] {
  -moz-appearance: textfield;
}
