.CurrencyXCurrency_ArrowCircleRightIcon,
.CurrencyXCurrency_XCircleIcon {
  width: 2rem;
  height: 2rem;
  translate: 0rem -1rem;
}

.CurrencyXCurrency_ArrowCircleRightIcon path {
  color: #7d00ff;
  stroke-width: 0.05rem;
}

.CurrencyXCurrency_XCircleIcon path {
  color: #ff0000;
  stroke-width: 0.05rem;
}
