@keyframes progressBarProgressAnimation {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.ProgressBar_Step {
  height: 6px;
  width: 100%;
  border-radius: 2px;
  margin-right: 0.25rem;
}

.ProgressBar_Step div {
  height: 6px;
  border-radius: 2px;
  animation: progressBarProgressAnimation linear;
}

.ProgressBar_Step.ProgressBar_Step--time {
  background-color: var(--fill-secondary);
}

.ProgressBar_Step.ProgressBar_Step--success div {
  background-color: var(--accent-secondary);
}

.ProgressBar_Step.ProgressBar_Step--warning div {
  background-color: var(--accent-tertiary);
}

.ProgressBar_Step.ProgressBar_Step--error div {
  background-color: var(--accent-quaternary);
}

.ProgressBar_Step.ProgressBar_Step--primary div {
  background-color: var(--accent-primary);
}

.ProgressBar_Step:not(.ProgressBar_Step.ProgressBar_Step--time).ProgressBar_Step--null
  div {
  background-color: var(--fill-secondary);
}
