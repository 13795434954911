.CurrencySelector_SelectorButton {
  position: relative;
  display: flex;
  align-items: center;
  align-self: flex-end;
  min-width: 5rem;
  height: 2.25rem;
  border-radius: 10rem;
  text-transform: uppercase;
  transition: 0.3s ease-out background-color;
  background-color: var(--fill-quaternary);
}

.CurrencySelector_SelectorButton.CurrencySelector_SelectorButton:disabled,
.CurrencySelector_SelectorButton.CurrencySelector_SelectorButton:disabled:hover {
  cursor: not-allowed;
}

.CurrencySelector_SelectorButton.CurrencySelector_SelectorButton:hover:enabled {
  background-color: var(--fill-secondary);
}

.CurrencySelector_SelectorButton.CurrencySelector_SelectorButton--without-code {
  text-transform: none;
  padding-left: 1.05rem;
  padding-right: 0.5rem;
}

.CurrencySelector_SelectorButton.CurrencySelector_SelectorButton--without-code
  p {
  margin-right: 2rem;
}

.CurrencySelector_SelectorButton.CurrencySelector_SelectorButton--with-code:after {
  background-color: #dcdcde;
  border-radius: 50%;
  background-size: 0.8rem;
  background-repeat: no-repeat;
  content: attr(data-currency-code);
  font-size: 0.6rem;
  height: 0.8rem;
  left: 1.2rem;
  bottom: 0.1rem;
  position: absolute;
  width: 0.8rem;
}
